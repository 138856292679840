<template>
	<div class="swiper-box">
		<!-- swiper1 -->
		<swiper class="swiper gallery-top" :options="swiperOptionTop" ref="swiperTop">
			<swiper-slide class="slide-1" v-for='item in imglist'>
				<img class="imgswiper" :src='item.url' />
				<div class="slide-footer">
					<div class="timesource">
						<time-source :data="{ time: item.created_at, source: item.source }"></time-source>
					</div>
					<div class="downloadbtn">
						<el-button size="mini" type="primary" @click="downloadImg(item.id)" icon="el-icon-download">下载高清图片
						</el-button>
					</div>

				</div>
			</swiper-slide>
			<div v-show="!single"  class="swiper-button-next swiper-button-white" slot="button-next"></div>
			<div v-show="!single"  class="swiper-button-prev swiper-button-white" slot="button-prev"></div>
		</swiper>
		<!-- swiper2 Thumbs -->
		<swiper v-show="!single" class="swiper gallery-thumbs" :options="swiperOptionThumbs" ref="swiperThumbs">
			<swiper-slide class="slide-1" v-for='item in imglist'>
				<img class="thumbimg" :src='item.url' />
			</swiper-slide>

			<div class="swiper-button-next swiper-button-white" slot="button-next"></div>
			<div class="swiper-button-prev swiper-button-white" slot="button-prev"></div>
		</swiper>
	</div>

</template>
<script>

import TimeSource from "@/components/cityreserch/TimeSource.vue";
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import { downloadZip } from '@/api/cityresearch'
export default {
	name: 'swiper-example-thumbs-gallery',
	title: 'Thumbs gallery with Two-way control',
	components: {
		Swiper,
		SwiperSlide,
		TimeSource
	},
	props: {
		imglist: {
			type: Array,
			default: function () {

				return []

			}
		},

	},

	data() {
		return {
			single: true, //单图
			// imglist: [],//图片列表
			swiperOptionTop: {
				// loop: true,
				loopedSlides: 3, // looped slides should be the same
				// spaceBetween: 10,
				centeredSlides: true,
				// leftedSlodes: true,
				navigation: {
					nextEl: '.swiper-button-next',
					prevEl: '.swiper-button-prev'
				}
			},
			swiperOptionThumbs: {
				// loop: true,
				loopedSlides: 3, // looped slides should be the same
				//  spaceBetween: 10,
				centeredSlides: true,
				// leftedSlodes: true,
				slidesPerView: 'auto',
				touchRatio: 0.8,
				slideToClickedSlide: true,
				navigation: {
					nextEl: '.swiper-button-next',
					prevEl: '.swiper-button-prev'
				}
			}
		}
	},
	watch: {
		imglist: {
			handler(ov) {
				if (ov.length === 1) {
					this.single = true
				} else {
					this.single = false
				}

			},
			deep: true,
		}
	},
	mounted() {
		this.$nextTick(() => {
			const swiperTop = this.$refs.swiperTop.$swiper
			const swiperThumbs = this.$refs.swiperThumbs.$swiper
			swiperTop.controller.control = swiperThumbs
			swiperThumbs.controller.control = swiperTop
		})
	},
	methods: {
		downloadImg(id) {
			downloadZip({ file_ids: id }).then(res => {
				const url = res.data
				window.location.href = url
			})
		}
	}
}
</script>
<style lang="scss" scoped>
.thumbsbox {
	border-radius: 5px;
	overflow: hidden;
}

.swiper-box {
	width: 90%;

	margin: 0 auto;
}

.swiper-slide {
	/*border: 1px solid black;*/
}

.swiper-slide img {
	width: 100%;
	height: 100%;
	// object-fit: cover;
}

.gallery-top .swiper-slide {

	height: calc(100vh - 230px);
	width: 80%;
	// box-shadow:

		// -2px 0 3px -1px #ccc, //左边阴影

		// 0 -2px 3px -1px #ccc, //顶部阴影

		// 0 2px 3px -1px #ccc, //底部阴影

		// 2px 0 3px -1px #ccc; //右边阴影
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
}

.gallery-top .swiper-slide img {
	border-radius: 5px;
	overflow: hidden;
}

.gallery-thumbs {
	// background:red;
	width: 380px;
	// position: relative;
	// background: red;
	// width: 100%;
	// border: solid 1px red;
	top: -140px;
	background: rgba(30, 30, 30, 0.3);
}

.gallery-thumbs .swiper-slide {
	height: 80px;
	width: 80px;
	border: solid 2px #fff;
	margin-left: 5px;
	margin-right: 5px;

	box-sizing: border-box;

	border-radius: 4px;
	opacity: 0.7;
}

.gallery-thumbs .swiper-slide.swiper-slide-active {
	opacity: 1;
	border:solid 2px #28a7e1;
	border-radius: 4px;
}

.slide-footer {
	position: relative;
	margin-bottom: 30px;
}

.timesource {
	position: relative;
	width: 100%;
	left: 0;
	top: 20px;
	display: flex;
	justify-content: flex-start;
}

.downloadbtn {
	width: 100%;
	position: absolute;
	top: 20px;
	display: flex;
	justify-content: center;
}
.imgswiper{
	object-fit: contain;
	background: #dedede;
}
.thumbimg {}
</style>